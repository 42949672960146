import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { NAVBAR } from '../../../config';
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import QuickStartButton from 'components/QuickStartButton';
import { NavSectionVertical } from 'components/nav-section';
import CollapseButton from './CollapseButton';
import QuickTaskDrawer from 'components/QuickTaskDrawer';
import { TASK, QUICK_ADD_RESPONSE } from 'models/quickadd';

import NavConfig from './NavConfig';
import { AxiosRequestConfig } from 'axios';
import { useGetQuickStartState } from 'api/frontend';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import { ExpiryAlert } from 'pages/product_plans/components/ExpiryAlert';
import { IconButton } from 'subframe/index';
import { QuickStartResponse, QuickStartResponseItem } from 'api/models';

const sortTasks = (a: QuickStartResponseItem, b: QuickStartResponseItem) => {
  return a.priority - b.priority;
};

const formatedTask = (tasksResponse: QuickStartResponse) => {
  const responseKeys = Object.keys(tasksResponse) as Array<
    keyof QuickStartResponse
  >;

  return responseKeys
    .map((key) => {
      return { ...tasksResponse[key], key };
    })
    .sort(sortTasks);
};

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

const fetchQuickAdds = (token: string) => {
  const config = {} as AxiosRequestConfig;
  config.headers = { Authorization: `Bearer ${token}` };
  const options = { request: config };
  const { data, error } = useGetQuickStartState(options);
  return { data: data, isLoading: !error && !data, isError: error };
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const [formattedTasks, setFormattedTasks] = useState<TASK[]>([]);
  const { account, currentOrganization } = useUserAccountState();
  const { data, isLoading } = fetchQuickAdds(account?.token);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const totalTasksNumber = formattedTasks.length;
  const tasksleft = useMemo(
    () =>
      formattedTasks?.reduce((previousValue, currentValue) => {
        return currentValue.status ? previousValue - 1 : previousValue;
      }, totalTasksNumber),
    [totalTasksNumber, formattedTasks],
  );
  useEffect(() => {
    if (data) {
      const { __status, ...restData } = data;
      const tasks = formatedTask(restData);
      setFormattedTasks(tasks);
    }
  }, [data]);

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateQuickStartState = (value: boolean) => {
    setIsTaskModalOpen(value);
  };

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          width: '100%',
          height: '100%',
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <Stack
          spacing={3}
          sx={{
            pt: 3,
            pb: 2,
            px: 2.5,
            flexShrink: 0,
            alignItems: isCollapse ? 'flex-start' : 'center',
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={isCollapse ? 'flex-start' : 'space-between'}
            width={'100%'}
          >
            <RouterLink
              to={`/orgs/${currentOrganization?.id}/accounts/${account?.accountId}/clusters`}
            >
              <Logo
                disabledLink
                showPointerCursor
                showText={!isCollapse}
                style={{ width: isCollapse ? 43 : 113 }}
              />
            </RouterLink>

            {isDesktop && !isCollapse && (
              <CollapseButton
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </Stack>
        </Stack>
        {formattedTasks.length > 0 && (
          <QuickStartButton
            isCollapse={isCollapse}
            totalTasksNumber={totalTasksNumber}
            tasksLeft={tasksleft}
            showText={!isCollapse}
            onClick={() => setIsTaskModalOpen(true)}
          />
        )}
        <NavSectionVertical
          navConfig={NavConfig()}
          isCollapse={isCollapse}
          id="sidenav"
        />
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>

      <ExpiryAlert isCollapseNav={isCollapse} />
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.paper',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isTaskModalOpen && (
        <QuickTaskDrawer
          loading={isLoading}
          isTaskModalOpen={isTaskModalOpen}
          isCollapse={isCollapse}
          onCloseSidebar={() => setIsTaskModalOpen(false)}
          updateQuickStartState={updateQuickStartState}
          tasks={formattedTasks}
          tasksleft={tasksleft}
        />
      )}
    </RootStyle>
  );
}
